/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { window } from 'browser-monads';
import { mainFilter } from '../../utilities/jobFilters';
import JobCard from '../JobCard';

export default function JobList() {
  const data = useStaticQuery(graphql`
    {
      allSanityJobs(
        sort: { fields: datePosted, order: DESC }
        filter: { filled: { ne: true } }
      ) {
        group(field: category___categoryName) {
          mainGroup: distinct(field: category___categoryName)
          nodes {
            datePosted
            dateExpiry
            sub {
              subcat
            }
          }
        }
        nodes {
          slug {
            current
          }
          title
          location {
            loc
          }
          date: datePosted(formatString: "MMM D, YYYY")
          datePosted
          dateExpiry
          category {
            categoryName
          }
          sub {
            subcat
          }
          workplace
          jobType
          salary
        }
      }
    }
  `);
  const allJobsRaw = data.allSanityJobs.nodes;
  const today = new Date().toISOString().slice(0, 10);

  // Don't list jobs that are to be posted later or expired
  const allJobsTillToday = allJobsRaw.filter(
    (job) => job.datePosted <= today && job.dateExpiry >= today
  );

  // Filter states
  const initialState = {
    category: 'all',
    location: 'all',
    fullTime: true,
    contract: true,
    onsite: true,
    hybrid: true,
    remote: true,
  };
  const [jobFilters, setJobFilters] = useState(() => {
    if (window.currentState === undefined) {
      window.currentState = initialState;
    }
    return window.currentState;
  });
  const [filteredJobs, setFilteredJobs] = useState(allJobsTillToday);

  useEffect(() => {
    setFilteredJobs(mainFilter(jobFilters, allJobsTillToday));
  }, [jobFilters]);

  const handleCategories = (value) => {
    setJobFilters({ ...jobFilters, category: value });
    window.currentState.category = value;
  };

  const handleLocations = (value) => {
    setJobFilters({ ...jobFilters, location: value });
    window.currentState.location = value;
  };

  // Handler functions
  const catGroup = data.allSanityJobs.group;
  const catWithSub = [];
  const uniqueArrOfloc = [];
  const locationList = [];

  // Category list on select box
  catGroup.forEach((cat) => {
    const subsub = [];
    catWithSub.push({ category: cat.mainGroup[0], sub: subsub });

    cat.nodes.forEach((i) => {
      if (i.datePosted <= today && i.dateExpiry >= today) {
        if (!subsub.includes(i.sub.subcat)) {
          subsub.push(i.sub.subcat);
        }
      }
    });
  });
  const catWithSubFiltered = catWithSub.filter((cat) => cat.sub.length !== 0);

  // Location list on select box
  allJobsTillToday.forEach((job) => {
    const location = job.location.loc;
    if (!uniqueArrOfloc.includes(location)) {
      locationList.push({ loc: location });
    }
    uniqueArrOfloc.push(location);
  });

  // Filter box show/hide: mobile
  const filterBox = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    const filterContent1 = filterBox.current;
    if (openFilter) {
      filterContent1.style.maxHeight = `${filterContent1.scrollHeight}px`;
    } else {
      filterContent1.style.maxHeight = null;
    }
  }, [openFilter]);

  return (
    <>
      <section id="job-list">
        <div className="wrapper">
          <div className="content">
            <div className="all-jobs">
              <div className="filter-text">
                <p className="mono">
                  {filteredJobs.length === allJobsTillToday.length
                    ? `Showing all ${filteredJobs.length} jobs`
                    : `Showing ${filteredJobs.length}/${allJobsTillToday.length} jobs`}
                </p>
                {filteredJobs.length !== allJobsTillToday.length && (
                  <div
                    className="clear-btn"
                    aria-hidden
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      window.currentState = initialState;
                      setJobFilters(window.currentState);
                    }}
                  >
                    Clear filters
                  </div>
                )}
              </div>

              {/* Job cards */}
              {filteredJobs.map((job, i) => (
                <JobCard job={job} key={i} />
              ))}
            </div>

            <div className="filter-container">
              <div className="filters">
                <div
                  className={
                    openFilter ? 'filter-toggle active' : 'filter-toggle'
                  }
                  aria-hidden
                  role="button"
                  tabIndex={-1}
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  <h3>{openFilter ? 'Hide filters' : 'Show filters'}</h3>
                  {filteredJobs.length !== allJobsTillToday.length && (
                    <div
                      className="clear-btn"
                      aria-hidden
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        window.currentState = initialState;
                        setJobFilters(window.currentState);
                      }}
                    >
                      Clear filters
                    </div>
                  )}
                </div>
                <div className="filter-content" ref={filterBox}>
                  <div className="form-group">
                    <h3>Category</h3>
                    <select
                      name="categories"
                      className="light-border"
                      value={jobFilters.category}
                      onChange={(e) => handleCategories(e.target.value)}
                    >
                      <option value="all">All Categories</option>
                      {catWithSubFiltered.map((catgroup, i) => (
                        <React.Fragment key={i}>
                          <option value={catgroup.category}>
                            {catgroup.category}
                          </option>
                          {catgroup.sub.map((a, b) => (
                            <option value={a} key={b} className="suboption">
                              {`– ${a}`}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                      {/* {catWithSubFiltered.map((catgroup, i) => (
                        <option value={`${catgroup.category}`} key={i}>
                          {catgroup.category}
                        </option>
                      ))} */}
                      {/* {catWithSubFiltered.map((catgroup, i) => (
                        <optgroup label={`∙ ${catgroup.category}`} key={i}>
                          {catgroup.sub.map((a, b) => (
                            <option value={a} key={b}>
                              {a}
                            </option>
                          ))}
                        </optgroup>
                      ))} */}
                    </select>
                  </div>
                  <div className="form-group locations">
                    <h3>Location</h3>
                    <select
                      name="locations"
                      className="light-border"
                      value={jobFilters.location}
                      onChange={(e) => handleLocations(e.target.value)}
                    >
                      <option value="all">All Locations</option>
                      {locationList.map((location, i) => (
                        <option value={location.loc} key={i}>
                          {location.loc}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group radios">
                    <h3>Workplace type</h3>
                    <div className="all-radios">
                      <div className="radio-group">
                        <input
                          type="checkbox"
                          name="onsite"
                          id="typeonsite"
                          className="light-border"
                          checked={jobFilters.onsite}
                          onChange={(e) => {
                            setJobFilters({
                              ...jobFilters,
                              onsite: e.target.checked,
                            });
                            window.currentState.onsite = e.target.checked;
                          }}
                        />
                        <label htmlFor="typeonsite">On-site</label>
                      </div>
                      <div className="radio-group">
                        <input
                          type="checkbox"
                          name="hybrid"
                          id="typehybrid"
                          className="light-border"
                          checked={jobFilters.hybrid}
                          onChange={(e) => {
                            setJobFilters({
                              ...jobFilters,
                              hybrid: e.target.checked,
                            });
                            window.currentState.hybrid = e.target.checked;
                          }}
                        />
                        <label htmlFor="typehybrid">Hybrid</label>
                      </div>
                      <div className="radio-group">
                        <input
                          type="checkbox"
                          name="remote"
                          id="typeremote"
                          className="light-border"
                          checked={jobFilters.remote}
                          onChange={(e) => {
                            setJobFilters({
                              ...jobFilters,
                              remote: e.target.checked,
                            });
                            window.currentState.remote = e.target.checked;
                          }}
                        />
                        <label htmlFor="typeremote">Remote</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group radios">
                    <h3>Employment type</h3>
                    <div className="all-radios">
                      <div className="radio-group">
                        <input
                          type="checkbox"
                          name="fulltime"
                          id="typefulltime"
                          className="light-border"
                          checked={jobFilters.fullTime}
                          // onChange={(e) => {
                          //   if (jobFilters.contract === false) {
                          //     setJobFilters({
                          //       ...jobFilters,
                          //       fullTime: e.target.checked,
                          //       contract: true,
                          //     });
                          //     window.currentState.fullTime = e.target.checked;
                          //     window.currentState.contract = true;
                          //   } else {
                          //     setJobFilters({
                          //       ...jobFilters,
                          //       fullTime: e.target.checked,
                          //     });
                          //     window.currentState.fullTime = e.target.checked;
                          //   }
                          // }}
                          onChange={(e) => {
                            setJobFilters({
                              ...jobFilters,
                              fullTime: e.target.checked,
                            });
                            window.currentState.fullTime = e.target.checked;
                          }}
                        />
                        <label htmlFor="typefulltime">Full time</label>
                      </div>
                      <div className="radio-group">
                        <input
                          type="checkbox"
                          name="contract"
                          id="typecontract"
                          className="light-border"
                          checked={jobFilters.contract}
                          onChange={(e) => {
                            setJobFilters({
                              ...jobFilters,
                              contract: e.target.checked,
                            });
                            window.currentState.contract = e.target.checked;
                          }}
                        />
                        <label htmlFor="typecontract">Contract</label>
                      </div>
                    </div>
                  </div>
                  {filteredJobs.length !== allJobsTillToday.length && (
                    <div
                      className="clear-btn"
                      aria-hidden
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        window.currentState = initialState;
                        setJobFilters(window.currentState);
                      }}
                    >
                      Clear filters
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
