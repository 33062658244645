import React from 'react';
import { Link } from 'gatsby';
import { PopupButton } from '@typeform/embed-react';

export default function Hero({ forHome = false, tag, title, para, heroImg }) {
  return (
    <>
      <div className={forHome ? 'hero hero-home' : 'hero hero-2'}>
        <div className="wrapper">
          <div className="text-content">
            {tag && <span className="tag">{tag}</span>}
            <h1>{title}</h1>
            <p className="mono">{para}</p>
            {forHome && (
              <div className="cta">
                <PopupButton id="uvQcDWjQ" className="button">
                  Hiring? Get Started
                </PopupButton>
                <Link to="/jobs" className="button outline">
                  Explore Jobs
                </Link>
              </div>
            )}
          </div>

          <div className="hero-img">
            <img src={heroImg} alt={title} className="invert-color" />
          </div>
        </div>
      </div>
    </>
  );
}
