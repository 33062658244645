import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function CtaUploadCV() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        ctaUploadCV {
          title
          para
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const { ctaUploadCV } = data.sanitySiteSettings;

  return (
    <>
      <div className="cta-box cta-upload">
        <div className="wrapper">
          <div className="cta-content">
            <div className="content">
              <h2>{ctaUploadCV.title}</h2>
              <p className="mono subtitle">{ctaUploadCV.para}</p>
              <button className="open-job-form open-upload-modal" type="button">
                Upload CV
              </button>
            </div>

            <div className="img">
              <GatsbyImage
                image={ctaUploadCV.icon.asset.gatsbyImageData}
                alt="upload CV"
                objectFit="contain"
                className="invert-color"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
