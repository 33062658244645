function getFirstLevelFilterData(filterState, allJobsTillToday) {
  const filteredJobs = [];

  if (filterState.category === 'all' && filterState.location === 'all')
    return allJobsTillToday;

  if (filterState.category === 'all' || filterState.location === 'all') {
    if (filterState.category === 'all') {
      allJobsTillToday.forEach((i) => {
        if (i.location.loc === filterState.location) {
          filteredJobs.push(i);
        }
      });
    } else {
      allJobsTillToday.forEach((i) => {
        if (
          i.category.categoryName === filterState.category ||
          i.sub.subcat === filterState.category
        ) {
          filteredJobs.push(i);
        }
      });
    }
  } else {
    allJobsTillToday.forEach((i) => {
      if (
        (i.category.categoryName === filterState.category ||
          i.sub.subcat === filterState.category) &&
        i.location.loc === filterState.location
      ) {
        filteredJobs.push(i);
      }
    });
  }
  return filteredJobs;
}

function getSecondLevelFilterData(filterState, allJobsTillToday) {
  const categoryAndLocationData = getFirstLevelFilterData(
    filterState,
    allJobsTillToday
  );

  if (filterState.fullTime && filterState.contract)
    return categoryAndLocationData;
  if (!filterState.fullTime && !filterState.contract) return [];
  if (filterState.fullTime)
    return categoryAndLocationData.filter((i) => i.jobType === 'fulltime');
  return categoryAndLocationData.filter((i) => i.jobType === 'contract');
}

export function mainFilter(filterState, allJobsTillToday) {
  const secondLevelFilterData = getSecondLevelFilterData(
    filterState,
    allJobsTillToday
  );

  if (!filterState.onsite && !filterState.hybrid && !filterState.remote)
    return [];

  if (!filterState.onsite) {
    if (!filterState.hybrid) {
      return secondLevelFilterData.filter((i) => i.workplace === 'remote');
    }
    if (!filterState.remote) {
      return secondLevelFilterData.filter((i) => i.workplace === 'hybrid');
    }
    return secondLevelFilterData.filter((i) => i.workplace !== 'onsite');
  }

  if (!filterState.hybrid) {
    if (!filterState.onsite) {
      return secondLevelFilterData.filter((i) => i.workplace === 'remote');
    }
    if (!filterState.remote) {
      return secondLevelFilterData.filter((i) => i.workplace === 'onsite');
    }
    return secondLevelFilterData.filter((i) => i.workplace !== 'hybrid');
  }

  if (!filterState.remote) {
    if (!filterState.onsite) {
      return secondLevelFilterData.filter((i) => i.workplace === 'hybrid');
    }
    if (!filterState.hybrid) {
      return secondLevelFilterData.filter((i) => i.workplace === 'onsite');
    }
    return secondLevelFilterData.filter((i) => i.workplace !== 'remote');
  }

  return secondLevelFilterData;
}

// export function mainFilter(filterState, allJobsTillToday) {
//   const secondLevelFilterData = getSecondLevelFilterData(
//     filterState,
//     allJobsTillToday
//   );
//   if (!filterState.remote) {
//     return secondLevelFilterData.filter((i) => !i.remote);
//   }
//   return secondLevelFilterData;
// }

//

// // Handler functions
// const uniqueArrOfcat = [];
// const uniqueArrOfloc = [];
// const categoryList = [];
// const locationList = [];

// // Category & Location list with numbers
// allJobsTillToday.forEach((job) => {
//   const category = job.category.categoryName;
//   const { location } = job;
//   const countCategory = allJobsTillToday.filter(
//     (x) => x.category.categoryName === job.category.categoryName
//   ).length;
//   const countLocation = allJobsTillToday.filter(
//     (x) => x.location === job.location
//   ).length;
//   if (!uniqueArrOfcat.includes(category)) {
//     categoryList.push({ name: category, count: countCategory });
//   }
//   if (!uniqueArrOfloc.includes(location)) {
//     locationList.push({ name: location, count: countLocation });
//   }
//   uniqueArrOfcat.push(category);
//   uniqueArrOfloc.push(location);
// });
