import React from 'react';
import { Link } from 'gatsby';

import IconLocation from '../icons/IconLocation';
import IconTimer from '../icons/IconTimer';
import IconMoney from '../icons/IconMoney';

export default function JobCard({ job }) {
  return (
    <Link to={`/jobs/${job.slug.current}`} className="job-card">
      <div className="top">
        <h3>{job.title}</h3>
        <div className="tags">
          {job.workplace && (
            <span className="job-tag remote">
              {job.workplace === 'onsite' && 'On-site'}
              {job.workplace === 'hybrid' && 'Hybrid'}
              {job.workplace === 'remote' && 'Remote'}
            </span>
          )}
          {job.jobType === 'fulltime' ? (
            <span className="job-tag fulltime">Full time</span>
          ) : (
            <span className="job-tag contract">Contract</span>
          )}
        </div>
      </div>

      <div className="bottom">
        <div className="bottom-left">
          <div className="info location">
            <IconLocation />
            <p>{job.location.loc}</p>
          </div>
          <div className="info date">
            <IconTimer />
            <p>Posted: {job.date}</p>
          </div>
          {job.salary && (
            <div className="info salary">
              <IconMoney />
              <p>{job.salary}</p>
            </div>
          )}
        </div>
        <div className="bottom-right category">
          <p>
            {job.category.categoryName} ({job.sub.subcat})
          </p>
        </div>
      </div>
    </Link>
  );
}
