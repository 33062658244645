import React from 'react';

export default function IconLocation() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#0B1823"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.14462 7.3975C3.28286 5.72215 4.04599 4.16003 5.28252 3.02121C6.51905 1.8824 8.13858 1.25016 9.81963 1.25H10.1796C11.8607 1.25016 13.4802 1.8824 14.7167 3.02121C15.9533 4.16003 16.7164 5.72215 16.8546 7.3975C17.0091 9.26791 16.4316 11.1253 15.2438 12.5783L11.2496 17.4633C11.0982 17.6486 10.9075 17.7979 10.6914 17.9005C10.4752 18.003 10.2389 18.0562 9.99963 18.0562C9.76035 18.0562 9.52408 18.003 9.30789 17.9005C9.0917 17.7979 8.90101 17.6486 8.74963 17.4633L4.75629 12.5783C3.56821 11.1254 2.99042 9.26802 3.14462 7.3975ZM12.9413 7.10833C13.0517 6.98985 13.1118 6.83315 13.1089 6.67123C13.1061 6.50931 13.0405 6.35483 12.926 6.24031C12.8115 6.1258 12.657 6.06021 12.4951 6.05735C12.3331 6.0545 12.1764 6.1146 12.058 6.225L9.58296 8.7L8.35796 7.475C8.30074 7.41359 8.23174 7.36434 8.15507 7.33018C8.07841 7.29602 7.99565 7.27765 7.91173 7.27617C7.82781 7.27469 7.74445 7.29013 7.66663 7.32156C7.5888 7.353 7.51811 7.39979 7.45876 7.45914C7.39941 7.51848 7.35262 7.58918 7.32119 7.667C7.28976 7.74483 7.27432 7.82818 7.2758 7.9121C7.27728 7.99602 7.29565 8.07878 7.32981 8.15545C7.36397 8.23211 7.41322 8.30111 7.47463 8.35833L9.14129 10.025C9.25848 10.142 9.41733 10.2078 9.58296 10.2078C9.74858 10.2078 9.90744 10.142 10.0246 10.025L12.9413 7.10833Z"
        />
      </svg>
    </>
  );
}
