import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import JobList from '../components/jobs/JobList';
import CtaUploadCV from '../components/CtaUploadCV';

import '../css/jobs/jobspage.scss';

export default function JobsPage({ data, location }) {
  const { title, heroText, heroImg } = data.sanityJobspage.heroSection;
  const { seo } = data.sanityJobspage;

  return (
    <>
      <SEO
        title={seo.title || title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout>
        <Hero
          tag="Jobs"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />
        <JobList />
        <CtaUploadCV />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityJobspage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
