import React from 'react';

export default function IconMoney() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#0B1823"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.5 3.5H2.5C2.224 3.5 2 3.74267 2 4.04167V15.9583C2 16.2573 2.224 16.5 2.5 16.5H17.5C17.776 16.5 18 16.2573 18 15.9583V4.04167C18 3.74267 17.776 3.5 17.5 3.5ZM16 12.1667H15.5C14.645 12.1667 14 12.8654 14 13.7917V14.3333H6V13.7917C6 12.8654 5.355 12.1667 4.5 12.1667H4V7.83333H4.5C5.355 7.83333 6 7.13458 6 6.20833V5.66667H14V6.20833C14 7.13458 14.645 7.83333 15.5 7.83333H16V12.1667Z" />
        <path d="M13.048 6.75H6.952C6.758 7.82575 5.993 8.6545 5 8.86467V11.1348C5.993 11.3455 6.758 12.1743 6.952 13.25H13.0475C13.242 12.1743 14.0065 11.3455 14.9995 11.1353V8.86521C14.007 8.6545 13.242 7.82575 13.048 6.75ZM10 11.625C9.1715 11.625 8.5 10.8975 8.5 10C8.5 9.10246 9.1715 8.375 10 8.375C10.8285 8.375 11.5 9.10246 11.5 10C11.5 10.8975 10.8285 11.625 10 11.625Z" />
      </svg>
    </>
  );
}
